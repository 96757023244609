/* SITE THEME FOR TPINE TRUCK RENTAL */
$theme-primary: (
    50 : #e7ede4,
    100 : #c2d2bc,
    200 : #99b48f,
    300 : #709662,
    400 : #528040,
    500 : #33691e,
    600 : #2e611a,
    700 : #275616,
    800 : #204c12,
    900 : #143b0a,
    A100 : #8cff74,
    A200 : #62ff41,
    A400 : #38ff0e,
    A700 : #2bf300,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$theme-accent: (
    50 : #feeee0,
    100 : #fcd4b3,
    200 : #fab880,
    300 : #f79c4d,
    400 : #f68626,
    500 : #f47100,
    600 : #f36900,
    700 : #f15e00,
    800 : #ef5400,
    900 : #ec4200,
    A100 : #ffffff,
    A200 : #ffe6e0,
    A400 : #ffbead,
    A700 : #ffaa93,
    contrast: (
        50: rgba(black, 0.87),
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

