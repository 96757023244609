// @font-face {
    @use '@angular/material' as mat;
    //   font-family: "San Francisco";
    //   font-weight: 400;
    //   src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-regular-webfont.woff");
    // }
    
    $theme-typography: mat.define-typography-config($font-family: "'SF Pro Text','SF Pro Icons', sofia-pro, Helvetica, sans-serif");
    // $theme-typography: mat-typography-config($font-family: "sofia-pro");
    
    // TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
    //  The following line adds:
    //    1. Default typography styles for all components
    //    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
    //  If you specify typography styles for the components you use elsewhere, you should delete this line.
    //  If you don't need the default component typographies but still want the hierarchy styles,
    //  you can delete this line and instead use:
    //    `@include mat.legacy-typography-hierarchy($theme-typography);`
    @include mat.all-component-typographies($theme-typography);
    @include mat.core();
    
    // @import "./theme/ng/gray-blue-orange-ng-mat.scss";
    @import "./theme/ws-themes/site-theme.scss";
    
    // Default Theme
    $default-primary: mat.define-palette($theme-primary);
    $default-accent: mat.define-palette($theme-accent);
    $default-warn: mat.define-palette(mat.$red-palette);
    // $default-theme: mat.define-light-theme($default-primary, $default-accent, $default-warn);
    $default-theme: mat.define-light-theme((color: (primary: $default-primary, accent: $default-accent, warn: $default-warn), density: 0, ));
    
    
    // Dark
    $dark-primary: mat.define-palette(mat.$blue-grey-palette);
    $dark-accent: mat.define-palette(mat.$amber-palette, A200, A100, A400);
    $dark-warn: mat.define-palette(mat.$deep-orange-palette);
    // $dark-theme: mat.define-dark-theme($dark-primary, $dark-accent, $dark-warn);
    $dark-theme: mat.define-dark-theme((color: (primary: $dark-primary, accent: $dark-accent, warn: $dark-warn), density: 0, ));
    // Light
    $light-primary: mat.define-palette(mat.$grey-palette, 200, 500, 300);
    $light-accent: mat.define-palette(mat.$brown-palette, 200);
    $light-warn: mat.define-palette(mat.$deep-orange-palette, 200);
    // $light-theme: mat.define-light-theme($light-primary, $light-accent, $light-warn);
    $light-theme: mat.define-light-theme((color: (primary: $light-primary, accent: $light-accent, warn: $light-warn), density: 0, ));
    
    // Use a scss for loop to include three themes
    $themes: $default-theme,
      $dark-theme,
      $light-theme;
    $themeClasses: ".default-theme",
      ".dark-theme",
      ".light-theme";
    
    // Following loop will add the themes to angular material as well as generate the colors.
    // components.
    
    // Just use default only. it keeps the size low.
    // @for $i from 1 through length($themes) {
    @for $i from 1 through 1 {
    
      // class name => .default-theme
      #{nth($themeClasses, $i)} {
        // add current theme => $default-theme
        @include mat.all-component-themes(nth($themes, $i));
    
        $theme: nth($themes, $i);
    
        // TODO NG14
        // @include mat-datetimepicker-theme($theme);
    
        --foreground: #{map-get(map-get($theme, foreground), text)};
        --background: #{map-get(map-get($theme, background), background)};
    
        $primary-palate: map-get($theme, primary);
        $primary: mat.get-color-from-palette($primary-palate);
        --primary: #{$primary};
        --primary-lighter-color: #{mat.get-color-from-palette($primary-palate, 50)};
        --background-color: #f3f3f3;
    
        .primary-light {
          color: #{mat.get-color-from-palette($primary-palate, 50)};
        }
    
        $accent-palate: map-get($theme, accent);
        $accent: mat.get-color-from-palette($accent-palate);
        --accent: #{$accent};
        --accent-lighter-color: #{mat.get-color-from-palette($accent-palate, 50)};
    
        .accent-light {
          color: #{mat.get-color-from-palette($accent-palate, 50)};
        }
    
        $warn-palate: map-get($theme, warn);
        $warn: mat.get-color-from-palette($warn-palate);
        --warn: #{$warn};
        --warn-lighter-color: #{mat.get-color-from-palette($warn-palate, 50)};
    
        // alert box
        .primary .mat-mdc-dialog-title {
          background-color: mat.get-color-from-palette($primary-palate);
        }
    
        .accent .mat-mdc-dialog-title {
          background-color: mat.get-color-from-palette($accent-palate);
        }
    
        .warn .mat-mdc-dialog-title {
          background-color: mat.get-color-from-palette($warn-palate);
        }
    
        .snack-primary {
          color: white;
          background-color: #{$primary};
        }
    
        .snack-accent {
          color: white;
          background-color: #{$accent};
    
          button {
            color: white;
          }
        }
    
        .snack-warn {
          color: white;
          background-color: #{$warn};
    
          button {
            color: white;
          }
        }
    
        // Globalr site wise classes.
        .container {
    
          // normal link
          a {
            color: mat.get-color-from-palette($primary-palate);
          }
    
          a:hover {
            color: mat.get-color-from-palette($accent-palate);
          }
    
          // alignment of text next to input icon.
          /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
          .mat-form-field-infix {
            margin-left: 10px;
          }
    
          .x2 {
            // color: mat-color($primary-palate);
            color: var(--primary);
            transform: scale(1.5);
          }
        }
    
        a.active-link {
          background: mat.get-color-from-palette($accent-palate);
        }
    
        // menu hover
        a.mat-mdc-list-item:hover,
        a.mat-mdc-menu-item:hover {
          color: #{mat.get-color-from-palette($accent-palate)};
    
          mat-icon,
          i {
            color: #{mat.get-color-from-palette($accent-palate)};
          }
        }
    
        // forms inline issue
        /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
        mat-card {
          max-width: 400px;
          margin: 10px;
        }
    
        /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
        mat-card-content p {
          line-height: 1.5em;
        }
    
        mat-form-field {
          display: block;
          clear: both;
        }
    
        // Remove background color of the form text field
        .mdc-text-field--filled:not(.mdc-text-field--disabled),
        .mat-mdc-form-field-focus-overlay {
          background-color: transparent;
          height: 44px;
        }
    
    
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-infix {
          margin-left: 10px; // some field do not show the spacing between icon and text inside the input.
        }
    
        // #region Video Home Page 
        .center-video {
          padding: 10px !important;
    
          /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
          mat-card {
            position: relative;
            padding: 10px;
            margin: 10px;
          }
    
          .mat-mdc-dialog-container {
            padding: 0px !important;
            background-color: transparent;
    
          }
        }
    
        .center-video-mobile {
          padding: 0px !important;
    
          /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
          mat-card {
            position: relative;
            padding: 5px;
            margin: 5px;
          }
    
          .mat-mdc-dialog-container {
            padding: 0px !important;
            background-color: transparent;
    
          }
        }
    
        // #endregion video
        // background cross hatch pattern
        .mat-drawer-container {
          background: #dddce2 url(assets/content_bg.jpg) repeat left top;
          // background: #dddce2 url(assets/robson-hatsukami-morgan-NKr0qBAkU4s_small.jpg);
        }
    
        // Button size increase
        .md {
          min-width: 100px;
          min-height: 45px;
    
          mat-icon {
            margin-right: 10px;
            transform: scale(1.1);
          }
    
          font-weight: 600;
          font-size: larger;
        }
    
        button.md-18 {
          padding: 0 10px 0px 4px;
          font-size: 18px;
    
          mat-icon {
            font-size: 18px;
            margin-top: 3px;
            margin-left: 3px;
          }
        }
    
        // #region ************  LIST MENU START *****************************
    
        .menu-list {
          list-style: none;
          padding-left: 20px;
        }
    
        .menu-list li {
          line-height: 55px;
        }
    
        .menu-list .menu-active i:last-child,
        .menu-list .menu-active mat-icon:last-child {
          display: none;
        }
    
        .menu-list .menu-active::after {
          font-family: "Font Awesome 5 Free";
          content: "\f111";
          font-weight: 800;
          font-size: 8px;
          position: absolute;
          height: 70px;
          line-height: 75px;
          margin-top: -58px;
          right: 33px;
          color: #8cc152;
        }
    
        .menu-list li a {
          color: #1f1f1f;
          display: block;
          position: relative;
        }
    
        .menu-list li span {
          font-size: 15px;
          padding-left: 40px;
          font-weight: 600;
        }
    
        .menu-list li em {
          position: absolute;
          left: 0px;
          margin-top: 18px;
          padding-left: 40px;
          font-style: normal;
          font-size: 11px;
          color: #8c8c8c;
        }
    
        .menu-list li i:first-child,
        .menu-list li mat-icon:first-child {
          position: absolute;
          height: 70px;
          line-height: 73px;
          font-size: 18px;
        }
    
        .menu-list li i:last-child,
        .menu-list li mat-icon:last-child {
          position: absolute;
          right: 9px;
          height: 55px;
          line-height: 55px;
          width: 55px;
          text-align: center;
          font-size: 14px;
          color: #777777;
          margin-top: 10px;
        }
    
        .menu-hide {
          position: absolute;
          right: -5px;
          top: 0px;
          height: 90px;
          line-height: 90px;
          width: 80px;
          text-align: center;
          font-size: 20px;
          color: #bf263c;
        }
    
        .menu-list-boxed li {
          // margin-right: 20px;
          padding-bottom: 18px;
          margin-bottom: 12px;
          border-radius: 10px;
          line-height: 45px;
          border-style: solid;
          border-width: 1px;
          border-color: var(--primary-lighter-color);
          // border: solid 1px rgba(0, 0, 0, 0.07);
        }
    
        .menu-list-boxed li i:last-child,
        .menu-list-boxed li mat-icon:last-child {
          right: -10px !important;
          top: -8px;
        }
    
        .menu-list-boxed li i:first-child,
        .menu-list-boxed li mat-icon:first-child {
          width: 40px;
          text-align: center;
          margin-left: 15px;
          font-size: 25px;
          margin-top: -6px;
        }
    
        .menu-list-boxed span {
          padding-left: 57px !important;
        }
    
        .menu-list-boxed em {
          padding-left: 57px !important;
          opacity: 0.76;
        }
    
        // #endregion ************  LIST MENU START *****************************
      }
    }
    
    .add-plus {
      mat-icon {
        font-size: 50px;
      }
    
      // width: 34px;
      // height: 34px;
      // border-radius: 44px;
      // font-size: 12px;
      position: fixed;
      z-index: 19;
      right: 50px;
      bottom: 25px;
      // color: #FFFFFF;
      // text-align: center;
      // line-height: 32px;
      // background: #499bea;
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#499bea", endColorstr="#207ce5", GradientType=0);
      transition: all 250ms ease;
    }
    
    html,
    body {
      height: 100%;
      // background: #dddce2 url(assets/content_bg.jpg) repeat left top;
      margin: 0;
      // font-family: sofia-pro, Roboto, "Helvetica Neue", sans-serif;
      font-family: "SF Pro Text", "SF Pro Icons", sofia-pro, Helvetica, sans-serif;
      overflow: hidden;
    }
    
    // container class
    .container {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      --cwidth: 100%;
    }
    
    @media (min-width: 640px) {
      .container {
        max-width: 640px;
        --cwidth: 640px;
        // background-color: gray;
      }
    }
    
    @media (min-width: 768px) {
      .container {
        max-width: 768px;
        --cwidth: 768%;
        // background-color: red;
      }
    }
    
    /* Large devices (laptops/desktops, 992px and up) */
    @media (min-width: 992px) {
      .container {
        max-width: 992px;
        --cwidth: 992px;
        // background-color: green;
      }
    }
    
    
    @media (min-width: 1024px) {
      .container {
        max-width: 1024px;
        --cwidth: 1024px;
        // background-color: rosybrown;
      }
    }
    
    @media (min-width: 1280px) {
      .container {
        max-width: 1280px;
        --cwidth: 1280px;
        // background-color: blueviolet;
      }
    }
    
    @media (min-width: 1366px) {
      .container {
        max-width: 1360px;
        --cwidth: 1360px;
        // background-color: blueviolet;
      }
    }
    
    @media (min-width: 1600px) {
      .container {
        max-width: 1600px;
        --cwidth: 1600px;
        // background-color: blueviolet;
      }
    }
    
    // menu
    
    // Utility Classes
    .hide {
      display: none;
    }
    
    // will work if the parent container width is full 1005
    .center {
      margin-left: auto;
      margin-right: auto;
    }
    
    .right {
      margin-left: auto;
    }
    
    .fb {
      //facebook btn
      background-color: #3b5998;
    }
    
    .relative {
      position: relative;
    }
    
    // only work in a relative positioned container
    .pull-right {
      position: absolute;
      right: 0px;
    }
    
    .pull-left {
      position: absolute;
      left: 0px;
    }
    
    // alt pull right/left
    .float-left {
      float: left;
    }
    
    .float-right {
      float: right;
    }
    
    .clear {
      clear: both;
    }
    
    // margins
    .m5 {
      margin: 5px;
    }
    
    .mt5 {
      margin-top: 5px;
    }
    
    .mr5 {
      margin-right: 5px;
    }
    
    .mb5 {
      margin-bottom: 5px;
    }
    
    .ml5 {
      margin-left: 5px;
    }
    
    .m10 {
      margin: 10px;
    }
    
    .mt10 {
      margin-top: 10px;
    }
    
    .mr10 {
      margin-right: 10px;
    }
    
    .mb10 {
      margin-bottom: 10px;
    }
    
    .ml10 {
      margin-left: 10px;
    }
    
    .m15 {
      margin: 15px;
    }
    
    .mt15 {
      margin-top: 15px;
    }
    
    .mr15 {
      margin-right: 15px;
    }
    
    .mb15 {
      margin-bottom: 15px;
    }
    
    .ml15 {
      margin-left: 15px;
    }
    
    .m20 {
      margin: 20px;
    }
    
    .mt20 {
      margin-top: 20px;
    }
    
    .mr20 {
      margin-right: 20px;
    }
    
    .mb20 {
      margin-bottom: 20px;
    }
    
    .ml20 {
      margin-left: 20px;
    }
    
    .m30 {
      margin: 30px;
    }
    
    .mt30 {
      margin-top: 30px;
    }
    
    .mr30 {
      margin-right: 30px;
    }
    
    .mb30 {
      margin-bottom: 30px;
    }
    
    .ml30 {
      margin-left: 30px;
    }
    
    // padding
    .p5 {
      padding: 5px;
    }
    
    .pt5 {
      padding-top: 5px;
    }
    
    .pr5 {
      padding-right: 5px;
    }
    
    .pb5 {
      padding-bottom: 5px;
    }
    
    .pl5 {
      padding-left: 5px;
    }
    
    .p10 {
      padding: 10px;
    }
    
    .pt10 {
      padding-top: 10px;
    }
    
    .pr10 {
      padding-right: 10px;
    }
    
    .pb10 {
      padding-bottom: 10px;
    }
    
    .pl10 {
      padding-left: 10px;
    }
    
    .p15 {
      padding: 15px;
    }
    
    .pt15 {
      padding-top: 15px;
    }
    
    .pr15 {
      padding-right: 15px;
    }
    
    .pb15 {
      padding-bottom: 15px;
    }
    
    .pl15 {
      padding-left: 15px;
    }
    
    .p16{
      padding: 16px;
    }
    
    .p20 {
      padding: 20px;
    }
    
    .pt20 {
      padding-top: 20px;
    }
    
    .pr20 {
      padding-right: 20px;
    }
    
    .pb20 {
      padding-bottom: 20px;
    }
    
    .pl20 {
      padding-left: 20px;
    }
    
    .p30 {
      padding: 30px;
    }
    
    .pt30 {
      padding-top: 30px;
    }
    
    .pr30 {
      padding-right: 30px;
    }
    
    .pb30 {
      padding-bottom: 30px;
    }
    
    .pl30 {
      padding-left: 30px;
    }
    
    // Icons
    
    i {
      margin-right: 10px;
      margin-left: 2px;
    }
    
    a {
      fa-icon {
        margin-right: 5px;
      }
    }
    
    .help-icon {
      font-size: 20px !important;
    }
    
    .primary-color {
      color: var(--primary);
    }
    
    .accent-color {
      color: var(--accent);
    }
    
    .warn-color {
      color: var(--warn);
    }
    
    .error {
      background-color: #f44336 !important;
      color: white;
      font-family: sofia-pro;
      font-size: medium;
    }
    
    // ::-webkit-scrollbar {
    //   width: 10px;
    //   height: 10px;
    //  // width: 0px;
    //   background: transparent; /* make scrollbar transparent */
    // }
    
    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 10px;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: rgb(51, 105, 30);
      border-radius: 10px;
    
      &:hover {
        background: rgb(43, 87, 25);
      }
    }
    
    .cdk-global-scrollblock {
      overflow-y: hidden;
    }
    
    
    // .mat-mdc-button .mdc-button__label, .mat-mdc-unelevated-button .mdc-button__label, .mat-mdc-raised-button .mdc-button__label, .mat-mdc-outlined-button .mdc-button__label {
    //   z-index: 1;
    //   display: flex;
    //   align-items: center;
    // }
    
    .default-theme .mat-mdc-card {
      padding: 16px;
    
      .mat-mdc-card-header {
        padding: 0 !important;
      }
    
      .mat-mdc-card-content {
        padding: 0 !important;
      }
    
      .mat-mdc-card-subtitle {
        line-height: var(--mdc-typography-subtitle2-line-height, 16px);
        font-size: 14px;
        margin-top: 2px;
      }
    
      h4 {
        font-size: 16px;
        margin: 16px 0;
      }
    }
    
    .mdc-dialog__title::before {
      height: 30px !important;
    }
    
    .default-theme .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
      padding-top: 14px !important;
      font-size: var(--mdc-typography-body1-font-size, 14px);
    }
    
    .mdc-list-group__subheader {
      margin: 0 !important;
      padding-top: 0 !important;
    }
    
    .mat-mdc-list-item:not(.mat-mdc-list-item-interactive),
    .mat-mdc-list-option:not(.mat-mdc-list-item-interactive) {
      padding: 0;
      display: flex;
      align-items: center;
    }
    
    .mdc-list-item-content {
      display: flex;
      align-items: center;
    }
    
    .default-theme .mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line,
    .default-theme .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line,
    .default-theme .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
      height: 28px;
    }
    
    .address-link {
      display: flex;
      flex-direction: row;
      font-size: 14px;
      align-items: center;
    }
    
    .default-theme .mat-mdc-form-field-focus-overlay {
      background-color: transparent;
    }
    
    .d-reverse {
      display: flex;
      flex-direction: row-reverse;
    }
    
    .mat-mdc-card-avatar {
      margin-bottom: 0 !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    .mat-mdc-menu-item .mdc-list-item__primary-text {
      display: flex;
    }
    
    .default-theme .mat-mdc-dialog-container .mdc-dialog__surface {
      min-height: 200px;
    }
    
    .d-flex {
      display: flex;
    }
    
    .d-a-center {
      align-items: center
    }
    
    .d-j-center {
      justify-content: center
    }
    
    .d-j-between {
      justify-content: space-between;
    }
    
    .d-wrap {
      flex-wrap: wrap;
    }
    
    .d-j-end{
      justify-content: end !important;
    }
    
    .ml-4 {
      margin-left: 4px;
    }
    
    .ml-auto{
      margin-left: auto;
    }
    
    .mr-auto{
      margin-right: auto;
    }
    
    .mx-auto{
      margin-left: auto;
      margin-right: auto;
    }
    
    .w-100{
      width: 100% !important;
    }
    
    .default-theme .mdc-text-field {
      padding: 0 !important;
    }
    
    .default-theme .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
      top: 25px;
    }
    
    .mdc-text-field--filled .mdc-floating-label--float-above {
      transform: translateY(-120%) scale(0.75);
    }
    
    .default-theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
      margin: 5px 0;
    }
    
    .default-theme .mat-mdc-form-field:not(.mat-form-field-no-animations) .mdc-text-field__input {
      margin-top: 4px;
    }
    
    .default-theme .mat-mdc-dialog-container .mdc-dialog__content {
      color: #000000de;
    }
    
    .default-theme .mat-mdc-form-field-icon-suffix {
      padding: 0 0 32px 4px;
    }
    
    .default-theme .mat-mdc-select-arrow {
      top: 12px;
      right: 4px;
    }
    
    .default-theme .mat-mdc-select-value {
      padding-top: 8px;
    }
    
    .default-theme .mat-mdc-select{
      font-size: 14px !important;
    }
    
    .default-theme .mat-mdc-checkbox .mdc-checkbox {
      padding: 11px 5px !important;
    }
    
    .default-theme .mat-mdc-radio-button .mdc-radio {
      padding: 0 !important;
    }
    
    .default-theme .mdc-text-field--disabled.mdc-text-field--filled {
      height: 46px !important;
    }
    
    mat-label{
      font-size: 14px;
    }
    .default-theme .mat-mdc-form-field-subscript-wrapper, .mat-mdc-form-field-bottom-align::before {
      font-size: 10px;
    }
    
    .default-theme {
      // padding: 0px 6px 0 6px !important;
      text-align: left !important;
      .mdc-data-table__header-cell {
        font-weight: 700;
      }
    }
    
    mat-card-title,mat-card-subtitle{
      margin-bottom: 5px;
    }
    .default-theme .mdc-text-field--disabled .mdc-text-field__input {
      color: rgb(0 0 0 / 56%);
    }
    .default-theme .mat-mdc-form-field-bottom-align::before {
      height: 11px;
    }
    
    .default-theme .mat-mdc-option.mdc-list-item {
      font-size: 14px !important;
      margin-bottom: 8px;
      border-bottom: 1px solid var(--primary);
    }
    
    .default-theme .mat-mdc-paginator-container {
      justify-content:center !important;
    }
    